import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { store } from "./stores/store.jsx"; // Import your Redux store
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="584126793485-i6rkbmlbfh512fbumqptv98a5sgl5q5v.apps.googleusercontent.com">
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
